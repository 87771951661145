'use client'
import { isValidElement, cloneElement, useRef, useEffect, useState } from 'react'
import { useRouter } from 'next/navigation'
import { useClientOnly } from 'hooks'
import { useConnectModal, ConnectButton } from '@rainbow-me/rainbowkit'
import { useAuthContext } from 'contexts'
import { buttonMessages, Button, type ButtonProps } from 'components/inputs'

type ConnectButtonWrapperProps = {
  connectViewProps?:
    | true
    | {
        className?: ButtonProps['className']
        title?: ButtonProps['title']
        leftIcon?: ButtonProps['leftIcon']
        size?: ButtonProps['size']
        styling?: ButtonProps['styling']
        onConnectClick?: ButtonProps['onClick']
      }
  withCallOnClickOnConnectSuccess?: boolean
}

const ConnectButtonWrapper: React.CFC<ConnectButtonWrapperProps> = (props) => {
  const { children, connectViewProps, withCallOnClickOnConnectSuccess = true } = props

  const { address } = useAuthContext()
  const { openConnectModal } = useConnectModal()
  const router = useRouter()
  const addressRef = useRef(address)
  const [isConnectClicked, setIsConnectClicked] = useState(false)

  const isClient = useClientOnly()

  useEffect(() => {
    if (isConnectClicked && address) {
      if (withCallOnClickOnConnectSuccess && isValidElement(children)) {
        children.props?.onClick?.()

        if (typeof children.props?.to === 'string') {
          router.push(children.props.to)
        }
      }
    }
    addressRef.current = address
  }, [address, isConnectClicked])

  const handleConnectClick: ButtonProps['onClick'] = (event) => {
    event.preventDefault()

    openConnectModal?.()
    setIsConnectClicked(true)

    if (
      typeof connectViewProps === 'object' &&
      typeof connectViewProps?.onConnectClick === 'function'
    ) {
      connectViewProps.onConnectClick(event)
    }
  }

  if (!address && connectViewProps) {
    const {
      className = 'lg:btn-size-big',
      title = buttonMessages.connect,
      leftIcon = 'ui/pig',
      size = 's',
      styling = 'main',
    } = typeof connectViewProps === 'object' ? connectViewProps : {}

    return (
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading'
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === 'authenticated')

          return (
            <div
              {...(!ready && {
                'aria-hidden': true,
                style: {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Button
                      className={className}
                      title={title}
                      leftIcon={leftIcon}
                      size={size}
                      styling={styling}
                      onClick={openConnectModal}
                    />
                  )
                }

                return <div className="bone h-10 min-w-24 rounded-5" />
              })()}
            </div>
          )
        }}
      </ConnectButton.Custom>
    )
  }

  if (isValidElement(children)) {
    const { onClick } = children.props

    const handleClick = (event: any) => {
      if (!address) {
        return handleConnectClick(event)
      }

      if (typeof onClick === 'function') {
        onClick(event)
      }
    }

    return cloneElement(children, { ...children.props, onClick: handleClick })
  }

  return children
}

export default ConnectButtonWrapper
