import { useQuery } from '@tanstack/react-query'
import { restApi } from 'helpers'
import { useAccount } from 'wagmi'
import { authDataRef, useAuthContext } from 'contexts'

type ProfileMeApiData =
  | {
      id: string
      balance: number
      ref_code: string
      profit_passive: number
      profit_game: number
      count_prises: number
      count_cases: number
      ref_count: number
      created_at: string
      wallet: Address

      error: never
    }
  | {
      error: string

      id: never
      balance: never
      ref_code: never
      profit_passive: never
      profit_game: never
      count_prises: never
      count_cases: never
      ref_count: never
      created_at: never
      wallet: never
    }

export const getProfileMeApiData = async () => {
  if (!authDataRef.current.token) {
    throw new Error('No token provided')
  }

  const { data } = await restApi.get<ProfileMeApiData>('/profile/me')

  if (data.wallet.toLowerCase() !== authDataRef.current.address?.toLowerCase()) {
    authDataRef.remove()
    return null
  }

  return data
}

const useProfileMe = () => {
  const { address } = useAccount()
  const { token } = useAuthContext()

  return useQuery({
    queryKey: ['profile', 'me', address],
    queryFn: getProfileMeApiData,
    enabled: Boolean(__CLIENT__ && address && token),
    refetchInterval: 300000,
  })
}

export default useProfileMe
