'use client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration' // eslint-disable-next-line import/newline-after-import
import relativeTime from 'dayjs/plugin/relativeTime'
// don't move, must be before any other import
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

import { IntlProvider } from '@locmod/intl'
import { QueryClientProvider } from '@tanstack/react-query'
import { SvgProvider, SvgSprite } from 'svg-provider'
import { queryClient } from 'contexts'

const BaseProviders: React.CFC = (props) => {
  const { children } = props

  return (
    <QueryClientProvider client={queryClient}>
      <SvgProvider>
        <IntlProvider locale="en">{children}</IntlProvider>
        <div className="absolute hidden h-0 w-0">
          <SvgSprite />
        </div>
      </SvgProvider>
    </QueryClientProvider>
  )
}

export default BaseProviders
