'use client'

import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
  useLayoutEffect,
} from 'react'
import localStorage from '@locmod/local-storage'
import { events } from '@locmod/event-aggregator'
import { isAddress } from 'viem'
import { useAccount } from 'wagmi'
import { restApi } from 'helpers'
import { openModal, useModalOpenState } from '@locmod/modal'
import { getProfileMeApiData } from 'hooks/useProfileMe'

export type AuthStorageData = {
  token: string
  address: Address
}

type AuthData = {
  refCode: string | undefined
  token: string | undefined
  address: Address | undefined
}

type AuthActions = {
  set(contextValues: AuthStorageData): void
  remove(): void
}

type AuthDataRef = AuthActions & {
  current: {
    refCode: string | undefined
    token: string | undefined
    address: Address | undefined
  }
}

type AuthContext = AuthData & AuthActions

const storageName = 'auth'
const eventName = 'token-updated'

const authStorageValue = __CLIENT__
  ? localStorage.getItem<AuthStorageData>(storageName) || { token: undefined, address: '' }
  : { token: undefined, address: '' }

export const authDataRef: AuthDataRef = {
  current: {
    refCode: __CLIENT__
      ? new URLSearchParams(window.location.search).get('ref') || undefined
      : undefined,
    token:
      typeof authStorageValue.token === 'string' && authStorageValue.token.length > 8
        ? authStorageValue.token
        : undefined,
    address: isAddress(authStorageValue.address) ? authStorageValue.address : undefined,
  },
  set({ token, address }: AuthStorageData) {
    this.current.token = token
    this.current.address = address
    localStorage.setItem<AuthStorageData>(storageName, { token, address })
    events.dispatch(eventName)
  },
  remove() {
    const cleanUp = () => {
      this.current.token = undefined
      this.current.address = undefined
      localStorage.removeItem(storageName)
      events.dispatch(eventName)
    }

    if (this.current.token) {
      restApi.delete('/profile/logout')
      setTimeout(cleanUp)
    } else {
      cleanUp()
    }
  },
}

export const AuthContext = createContext<AuthContext>(null as any)
export const useAuthContext = () => useContext(AuthContext)

const AuthProvider: React.CFC = (props) => {
  const { children } = props

  const { address } = useAccount()
  const [contextValues, setValues] = useState<AuthData>({} as any)
  const isSiweModalOpen = useModalOpenState('SiweModal')
  const prevAddressRef = useRef(address)

  const remove = useCallback(() => {
    authDataRef.remove()
  }, [])

  const set: AuthContext['set'] = useCallback((values) => {
    authDataRef.set(values)
  }, [])

  useMemo(() => {
    events.subscribe(eventName, () =>
      setValues((prev) => ({
        ...prev,
        ...authDataRef.current,
      })),
    )
  }, [])

  useLayoutEffect(() => {
    getProfileMeApiData()
      .then((data) => {
        if (data) {
          setValues((prev) => ({
            ...prev,
            ...authDataRef.current,
          }))
        }
      })
      .catch(() => {})
  }, [])

  useLayoutEffect(() => {
    if (!__CLIENT__) {
      return
    }

    if (
      authDataRef.current.address &&
      ((!address && prevAddressRef.current) ||
        (address !== prevAddressRef.current && address !== authDataRef.current.address))
    ) {
      authDataRef.remove()
    }

    if (!isSiweModalOpen && address && address !== authDataRef.current.address) {
      openModal('SiweModal')
    }

    prevAddressRef.current = address
  }, [address, isSiweModalOpen])

  const value = {
    ...contextValues,
    set,
    remove,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
